const appSdk = {
    token:'',
    userInfo:'',
    applicationName:'',
    applicationVersion:'',
    brand:'',
    deviceId:'',
    apiVersion:'',
    init(){
        window.APPSDK = this;
        window.returnToken = this._returnToken;
        window.returnUserInfo = this._returnUserInfo;
        window.returnApplicationName = this._returnApplicationName;
        window.returnApplicationVersion = this._returnApplicationVersion;
        window.returnBrand = this._returnBrand;
        window.returnDeviceId = this._returnDeviceId;
        window.returnApiVersion = this._returnApiVersion;
        window.clientCallbackHandle = (type)=>{
            switch (type) {
              case 'loginSuccess':
                window.location.reload();
                break;
              case 'finishBindXboxLive':
                localStorage.setItem('after_bind',1);
                window.location.reload();
                break;
              default:
                break;
            }
        }
    },
    getToken(){
        switch (this.checkUA()) {
            case 'Android':
                this.token = window.Android.getToken();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getToken.postMessage(null);
                break;
            default:
                console.log("get token");
                break;
        }
    },
    getUserInfo(){
        switch (this.checkUA()) {
            case 'Android':
                this.userInfo = window.Android.getUserInfo();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getUserInfo.postMessage(null);
                break;
            default:
                console.log("get token");
                break;
        }
    },
    getAppName(){
        switch (this.checkUA()) {
            case 'Android':
                this.applicationName = window.Android.getAppName();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getApplicationName.postMessage(null);
                break;
            default:
                console.log("get appname");
                break;
        }
    },
    getVersion(){
        switch (this.checkUA()) {
            case 'Android':
                this.applicationVersion = window.Android.getVersionCode();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getApplicationVersion.postMessage(null);
                break;
            default:
                console.log("get version");
                break;
        }
    },
    getBrand(){
        switch (this.checkUA()) {
            case 'Android':
                this.brand = window.Android.getBrand();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getBrand.postMessage(null);
                break;
            default:
                console.log("get brand");
                break;
        }
    },
    getDeviceId(){
        switch (this.checkUA()) {
            case 'Android':
                this.deviceId = window.Android.getDeviceId();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getDeviceId.postMessage(null);
                break;
            default:
                console.log("get device");
                break;
        }
    },
    getApiVersion(){
        switch (this.checkUA()) {
            case 'Android':
                this.apiVersion = window.Android.getApiVersion();
                break;
            case 'IOS':
                window.webkit.messageHandlers.getApiVersion.postMessage(null);
                break;
            default:
                console.log("get api version");
                break;
        }
    },
    toast(msg){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.toast(msg);
                break;
            case 'IOS':
                window.webkit.messageHandlers.showToast.postMessage({msg:msg});
                break;
            default:
                alert(msg);
                break;
        }
    },
    openPage(path){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.goActivity(path);
                break;
            case 'IOS':
                window.webkit.messageHandlers.openPage.postMessage({page:path});
                break;
            default:
                console.log(path);
                break;
        }
    },
    showShare(title,content,link,img){
        let share = {title:title,content:content,link:link,img:img};
        switch (this.checkUA()) {
            case 'Android':
                window.Android.showShare(JSON.stringify(share));
                break;
            case 'IOS':
                window.webkit.messageHandlers.showShare.postMessage(share);
                break;
            default:
                console.log(JSON.stringify(share));
                break;
        }
    },
    setShare(title,content,link,img){
        let share = {title:title,content:content,link:link,img:img};
        switch (this.checkUA()) {
            case 'Android':
                window.Android.setShare(JSON.stringify(share));
                break;
            case 'IOS':
                window.webkit.messageHandlers.setShare.postMessage(share);
                break;
            default:
                console.log(JSON.stringify(share));
                break;
        }
    },
    goLogin(){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.goLogin();
                break;
            case 'IOS':
                window.webkit.messageHandlers.goLogin.postMessage(null);
                break;
            default:
                console.log("前往登录");
                break;
        }
    },
    closeWebview(){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.finish();
                break;
            case 'IOS':
                window.webkit.messageHandlers.refreshXboxView.postMessage(null);
                break;
            default:
                console.log("close webview");
                break;
        }
    },
    contactKefu(){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.contactKefu();
                break;
            case 'IOS':
                window.webkit.messageHandlers.contactKefu.postMessage(null);
                break;
            default:
                console.log("contactKefu");
                break;
        }
    },
    bindXboxLive(){
        switch (this.checkUA()) {
            case 'Android':
                window.Android.bindXboxlive();
                break;
            case 'IOS':
                window.webkit.messageHandlers.bindxboxlive.postMessage(null);
                break;
            default:
                console.log("bindXboxLive");
                break;
        }
    },
    checkUA(){
        let u = navigator.userAgent;
        if(u.indexOf('XGP365_ANDROID') > -1){
            return 'Android';
        }
        if(u.indexOf('XGP365_IOS') > -1){
            return 'IOS';
        }
        return 'default';
    },
    _returnToken(data){
        window.APPSDK.token = data;
    },
    _returnUserInfo(data){
        window.APPSDK.userInfo = data;
    },
    _returnApplicationName(data){
        window.APPSDK.applicationName = data;
    },
    _returnApplicationVersion(data){
        window.APPSDK.applicationVersion = data;
    },
    _returnBrand(data){
        window.APPSDK.brand = data;
    },
    _returnDeviceId(data){
        window.APPSDK.deviceId = data;
    },
    _returnApiVersion(data){
        window.APPSDK.apiVersion = data;
    },
}

export default appSdk;