import CallApp from "callapp-lib";
const callapp = {
    options:{
        universal: {
          host: "api.xgp365.com",
        }, //要打开的 APP 的标识
        intent: {
          package: "com.liuliqiu.xgp365", //包名
          scheme: "xgp365",
        },
        scheme: {
          protocol: "xgp365",
        },
        //apple store
        appstore: "https://apps.apple.com/cn/app/id1613280231",
        //应用宝
        yingyongbao:"https://a.app.qq.com/o/simple.jsp?pkgname=com.liuliqiu.xgp365",
        fallback: "https://a.app.qq.com/o/simple.jsp?pkgname=com.liuliqiu.xgp365", //唤端失败后跳转的地址
      },
      client:null,
      isSupportWeibo:true,
      init(){
        this.client = new CallApp(this.options);
      },
      open(object){
        console.log("打开页面的调用",object);
        this.client.open(object);
      }
}
export default callapp;