import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        component: () => import('@/views/IndexView'),
    },
    {
        path: '/content',
        component: () => import('@/views/Share/ContentView'),
    },
    {
        path: '/agreement',
        component: () => import('@/views/Common/AgreementView'),
    },
    // {
    //     path: '/game',
    //     component: () => import('@/views/Share/GameView'),
    // },
    {
        path: '/qa',
        component: () => import('@/views/Share/QAView'),
    },
    {
        path: '/topic',
        component: () => import('@/views/Share/TopicView'),
    },
    {
        path: '/download',
        component: () => import('@/views/Common/DownloadView'),
    },
    {
        path: '/sign/main',
        component: () => import('@/views/Activity/Sign/SignView'),
    },
    {
        path: '/sign/reward',
        component: () => import('@/views/Activity/Sign/RewardView'),
    },
    {
        path: '/sign/record',
        component: () => import('@/views/Activity/Sign/RecordView'),
    },
    {
        path: '/sign/index',
        component: () => import('@/views/Activity/Sign/indexView'),
    },
    {
        path: '/mall/xgp',
        component: () => import('@/views/Activity/Mall/XgpView'),
    },
    {
        path: '/404',
        component: () => import('@/views/Common/404View'),
    },
    {
        path: '/data/aoe2',
        component: () => import('@/views/Data/AOE2/list.vue'),
    },
    {
        path: '/data/aoe2/list',
        component: () => import('@/views/Data/AOE2/list2.vue'),
    },
    {
        path: '/data/aoe2/detail',
        component: () => import('@/views/Data/AOE2/detail.vue'),
    },
    {
        path: '/data/aoe2/main',
        component: () => import('@/views/Data/AOE2/main.vue'),
    },
    {
        path: '/fantuan/exchange',
        component: () => import('@/views/Activity/Fantuan/ExchangeView.vue'),
    },
    {
        path: '/download2',
        component: () => import('@/views/Common/Download2View.vue'),
    },
    {
        path: '/rank/savemoney',
        component: () => import('@/views/Activity/SaveMoneyRank/RankView.vue'),
    },
    {
        path: '/team',
        component: () => import('@/views/Share/TeamView.vue'),
    },
    {
        path: '/bundle',
        component: () => import('@/views/Share/BundleView.vue'),
    },
    {
        path: '/bundleV2',
        component: () => import('@/views/Share/BundleV2View.vue'),
    },

    // 会员详情
    {
        path: '/member',
        component: () => import('@/views/Member/InfoView.vue'),
    },

    {
        path: '/converter/pgp3',
        component: () => import('@/views/Member/PGP3View.vue'),
    },
    {
        path: '/converter/pgp9',
        component: () => import('@/views/Member/PGP9View.vue'),
    },
    // 游戏详情
    {
        path: '/game',
        component: () => import('@/views/Game/IndexInfo.vue'),
    },
    // 合集详情
    {
        path: '/collectionInfo',
        component: () => import('@/views/Game/CollectionInfo.vue'),
    },
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export default router
