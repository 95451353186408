import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import appSdk from './utils/app-sdk'
import VueClipboard from 'vue-clipboard2'
import callapp from './utils/callapp'
import wx from 'weixin-js-sdk'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.prototype.ajax = axios
Vue.prototype.$appSdk = appSdk
Vue.prototype.$callapp = callapp
Vue.prototype.$wx = wx

Vue.config.productionTip = false

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
