<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { get } from "./api/index";
export default {
  name: 'App',
  created(){
    console.log('初始化');
    document.title = '西瓜皮365';
  },
  methods: {
  },
}
</script>
<style>
  #app{
    overflow: hidden;
  }
  html{
    padding: 0;
    margin: 0;
    font-size: calc(100vw/7.5);
    width: 100%;
  }
  body{
    padding: 0;
    margin: 0;
  }
  div,span{
    color: #333;
    font-size: 0.28rem;
    line-height: 1;
    box-sizing: border-box;
  }
  .bgImg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .ell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
.pt120{
    padding-top: 1.2rem;
}
</style>